import Rails from "@rails/ujs";
import "@fortawesome/fontawesome-free/js/all";
window.$ = window.jQuery = require("jquery");
require("semantic-ui-sass/semantic-ui");
Rails.start();

window.show_error_dialog = function (msg) {
  alert(`An error has occurred: ${msg}`);
};

window.change_property_child_form = function (val) {
  if (val == "land") {
    $(".land_form").show();
    $(".apartment_ownership_form").hide();
    $(".apartment_ownership_form .apartment_ownership_kind_select").attr(
      "required",
      false
    );
    $(".condominium_ownership_form .condominium_ownership_kind_select").attr(
      "required",
      false
    );
  } else if (val == "apartment_ownership") {
    $(".land_form").hide();
    $(".apartment_ownership_form").show();
    $(".condominium_ownership_form").hide();
    $(".apartment_ownership_form .apartment_ownership_kind_select").attr(
      "required",
      true
    );
    $(".condominium_ownership_form .condominium_ownership_kind_select").attr(
      "required",
      false
    );
  } else if (val == "condominium_ownership") {
    $(".land_form").hide();
    $(".apartment_ownership_form").hide();
    $(".condominium_ownership_form").show();
    $(".apartment_ownership_form .apartment_ownership_kind_select").attr(
      "required",
      false
    );
    $(".condominium_ownership_form .condominium_ownership_kind_select").attr(
      "required",
      true
    );
  } else {
    $(".land_form").hide();
    $(".apartment_ownership_form").hide();
    $(".condominium_ownership_form").hide();
    $(".apartment_ownership_form .apartment_ownership_kind_select").attr(
      "required",
      false
    );
    $(".condominium_ownership_form .condominium_ownership_kind_select").attr(
      "required",
      false
    );
  }
};

window.set_current_situation_select = function (val, current_situation_val) {
  $(".current_situation_parent select").html("");
  let options;
  if (val == "land") {
    options = $(".current_situation_parent").attr("data-land");
  } else {
    options = $(".current_situation_parent").attr("data-not-land");
  }
  $(".current_situation_parent select").append("<option value=''></option>");
  JSON.parse(options).forEach(function (option) {
    $(".current_situation_parent select").append(
      `<option value="${option[1]}" ${
        current_situation_val == option[1] ? "selected" : ""
      }
      >${option[0]}</option>`
    );
  });
};
window.get_station_names = function (dom) {
  if (dom.val() != "") {
    $.ajax({
      url: "/station_names_api",
      type: "POST",
      dataType: "json",
      data: {
        access_key: "NMT8JvUvnNmrdC",
        q: { name_cont: dom.val() },
      },
    })
      .done(function (res, status, xhr) {
        if (res.result == "success") {
          const modal_id = dom.attr("data-modal-id");
          let modal = $(`#modal_${modal_id}`);
          modal.find(".flex_content").html("");
          const station_names_length = res.data.length;
          const station_names = res.data;
          station_names.forEach(function (station_name) {
            modal.find(".flex_content").append(
              `<a class="station_name"
                data-modal-id="${modal_id}"
                data-station-id="${station_name.id}"
                data-station-name="${station_name.name}"
              >
              ・${station_name.name}(${station_name.line_name})
              </a>`
            );
          });
          modal.find(".count span").text(station_names_length);
        } else {
          show_error_dialog(res.message);
        }
      })
      .fail(function (xhr, status, error) {
        show_error_dialog(error);
      });
  }
};

window.toCamelCase = function (str) {
  return str
    .split("_")
    .map(function (word, index) {
      if (index === 0) {
        return word.toLowerCase();
      }
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join("");
};

window.calcAge = function (date) {
  const diff = new Date() - new Date(date);
  const year = Math.floor(diff / 1000 / 60 / 60 / 24 / 30 / 12);
  return year;
};

window.calcRound = function (val) {
  const digit = 2;
  return Math.round(val * Math.pow(10, digit)) / Math.pow(10, digit);
};

window.calcTsubo = function (squareMeter) {
  const data = calcRound(squareMeter * 0.3025);
  return data;
};

window.calcSquareMeterPrice = function (propertyPrice, squareMeter) {
  const data = calcRound(propertyPrice / squareMeter);
  return data;
};

window.calcTsuboPrice = function (propertyPrice, tsubo) {
  const data = calcRound(propertyPrice / tsubo);
  return data;
};

window.calcSquareMeterPriceAndTsuboPrice = function () {
  const propertyPrice = $(".propertyPrice").val();
  if (propertyPrice && propertyPrice > 0) {
    const selectedVal = $(".property_attributes_select")
      .find("option:selected")
      .val();
    if (selectedVal) {
      const type = toCamelCase(selectedVal);
      const squareMeter = $("." + type + "SquareMeter").val();
      if (squareMeter && squareMeter > 0) {
        const squareMeterPrice = calcSquareMeterPrice(
          propertyPrice,
          squareMeter
        );
        $("." + type + "SquareMeterPrice").val(squareMeterPrice);
      } else {
        $("." + type + "SquareMeterPrice").val("");
      }
      const tsubo = $("." + type + "Tsubo").val();
      if (tsubo && tsubo > 0) {
        const tsuboPrice = calcTsuboPrice(propertyPrice, tsubo);
        $("." + type + "TsuboPrice").val(tsuboPrice);
      } else {
        $("." + type + "TsuboPrice").val("");
      }
    }
  }
};

$(document).on("change", ".propertyPrice", function (e) {
  calcSquareMeterPriceAndTsuboPrice();
});

$(document).on("change", ".squareMeter", function (e) {
  const val = $(this).val();
  const targetClassName = $(this).attr("data_tsubo_class_name");
  const tsubo = calcTsubo(val);
  if (val && val > 0 && tsubo && tsubo > 0) {
    $("." + targetClassName).val(tsubo);
  } else {
    $("." + targetClassName).val("");
  }
  calcSquareMeterPriceAndTsuboPrice();
});

$(document).on("click", "#menu-btn", function (e) {
  $("body").toggleClass("menu_active");
});

$(document).on("click", "#dimmer", function (e) {
  $("body").removeClass("menu_active");
});

document.addEventListener("DOMContentLoaded", () => {
  // railsがFormのsubmit時に勝手にsubmitボタンをdisabledするのに対処
  $("form").submit(function () {
    let form = $(this);
    setTimeout(() => {
      form.find("[type='submit']").each(function (idx, elm) {
        $(elm).removeAttr("disabled");
      });
    }, 1000);
  });

  //Flashメッセージを5秒後に非表示
  if ($(".flash_message")[0]) {
    setTimeout(function () {
      $(".flash_message").fadeOut();
    }, 3000);
  }

  // semantic-ui設定
  $(".dropdown").dropdown({
    fullTextSearch: true,
    placeholder: "",
  });
  $(".ui.checkbox").checkbox();
  $(".ui.accordion").accordion();

  if (window.innerWidth < 1000) {
    $("body").removeClass("menu_active");
  }

  setTimeout(function () {
    $(".global_navi").css("transition", "0.3s");
    $(".main_container").css("transition", "0.3s");
    $("header").css("transition", "0.3s");
    $("#menu-btn").css("transition", "0.3s");
    $("#menu-btn i").css("transition", "0.2s");
  }, 600);
});

$(document).ready(function () {
  $(".ui.popup").popup();

  if (
    $("td").hasClass("apartmentOwnershipAge") &&
    $(".apartmentOwnershipBuildingDate").text() !== ""
  ) {
    const date = $(".apartmentOwnershipBuildingDate").attr("data_date");
    const year = calcAge(date);
    $(".apartmentOwnershipAge").text(year + "年");
  }
  if (
    $("td").hasClass("condominiumOwnershipAge") &&
    $(".condominiumOwnershipBuildingDate").text() !== ""
  ) {
    const date = $(".condominiumOwnershipBuildingDate").attr("data_date");
    const year = calcAge(date);
    $(".condominiumOwnershipAge").text(year + "年");
  }
});
